import {Component, computed, ElementRef, inject, input, NgZone, output, signal, viewChild} from '@angular/core';
import {Resource} from "@models/resource";
import {ResourceService} from "@services/resource.service";
import {ToastService} from "@services/toast.service";
import {ResourceFormComponent} from './resource-form/resource-form.component';
import {CommonModule} from "@angular/common";
import {$localize} from "@angular/localize/init";

@Component({
    selector: 'app-resource-picker',
    templateUrl: './resource-picker.component.html',
    styleUrls: ['./resource-picker.component.css'],
    standalone: true,
    imports: [ResourceFormComponent, CommonModule]
})
export class ResourcePickerComponent {
    private resourceService = inject(ResourceService);
    private toast = inject(ToastService);
    private ngZone = inject(NgZone);

    readonly validating = input.required<boolean>();
    readonly resource = input<Resource | null>();
    readonly resourceChange = output<Resource>();

    protected cancelBtn = viewChild<ElementRef<HTMLButtonElement>>('cancelBtn');

    protected savingResource = signal(false);

    protected displayedResource = computed(() => this.resource() ?? this.resourceService.getDefaultResource());
    protected validResource = computed(() => !!this.resource().Id);

    protected publicResources: Resource[] = [];
    protected privateResources: Resource[] = [];

    constructor() {
        this.resourceService.publicImages().subscribe((resources) => this.publicResources = resources);
        this.resourceService.privateImages().subscribe((resources) => this.privateResources = resources);

        this.resourceService.resourceChange$.subscribe(() => {
            this.privateResources = this.resourceService.getCachedResources();
        });
    }

    selectResource(resource: Resource): void {
        this.resourceChange.emit(resource);
        this.cancelBtn().nativeElement.click();
    }

    saveResource(resource: Resource): void {
        this.savingResource.set(true);

        // Create an image element, load the image in a 400x400 canvas and export it as a blob
        // This is done to resize small images
        const canvasImg = document.createElement('img');
        canvasImg.addEventListener('load', () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = 400;
            canvas.height = 400;

            ctx.drawImage(canvasImg, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                resource.Blob = blob;
                this.resourceService.uploadImage(resource).subscribe(() => {
                    this.savingResource.set(false);
                    URL.revokeObjectURL(canvasImg.src);
                    this.ngZone.run(() => this.toast.success($localize`Resource saved`));
                });
            })
        });

        canvasImg.src = URL.createObjectURL(resource.Blob);
    }
}

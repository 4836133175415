import {PlanningConfiguration} from "@models/planning-configuration";

export class Client {
    private _password: string;
    constructor(
        private _id: string | null,
        private _firstName: string,
        private _lastName: string,
        private _gender?: string,
        private _birth?: number,
        private _username?: string | null,
        private _name?: string,
        private _planningConfiguration?: PlanningConfiguration
    ) {
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value.trim();
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value.trim();
    }

    get gender(): string {
        return this._gender;
    }

    set gender(value: string) {
        this._gender = value;
    }

    get birth(): number {
        return this._birth;
    }

    set birth(value: number) {
        this._birth = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value.trim();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get planningConfiguration(): PlanningConfiguration {
        return this._planningConfiguration;
    }

    set planningConfiguration(value: PlanningConfiguration) {
        this._planningConfiguration = value;
    }
}

@if (resource) {
    <div
        class="d-table position-relative mx-auto"
        data-bs-toggle="modal" data-bs-target="#resourceModal">
        <img [src]="displayedResource().Link"
             [ngClass]="validating() && !validResource() ? 'border invalid-photo' : ''"
             class="d-block rounded-circle"
             width="120"
             alt="John Doe"
             style="cursor: pointer"
        >
        <button type="button"
                class="btn btn-icon btn-light bg-white btn-sm border rounded-circle shadow-sm position-absolute bottom-0 end-0 mt-4"
                data-cy="change-picture-button"
                data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Change picture"
                aria-label="Change picture">
            <i class="bx bx-refresh"></i>
        </button>
    </div>
}

<div id="resourceModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" i18n>Image picker</h1>
                <button #cancelBtn type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" style="height: calc(100vh - 200px); overflow: scroll">
                <ul class="nav nav-tabs" id="resourcesTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="public-resources-tab" data-bs-toggle="tab"
                                data-bs-target="#public-resources-tab-pane" type="button" role="tab"
                                aria-controls="public-resources-tab-pane" aria-selected="true" i18n>
                            Public images
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="private-resources-tab" data-bs-toggle="tab"
                                data-bs-target="#private-resources-tab-pane" type="button" role="tab"
                                aria-controls="private-resources-tab-pane" aria-selected="false" i18n>
                            Private images
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="private-resources-form-tab" data-bs-toggle="tab"
                                data-bs-target="#private-resources-form-tab-pane" type="button" role="tab"
                                aria-controls="private-resources-form-tab-pane" aria-selected="false" i18n>
                            Add a private image
                        </button>
                    </li>
                </ul>

                <div class="tab-content" id="resourcesTabContent">
                    <div class="tab-pane active show fade" id="public-resources-tab-pane" role="tabpanel"
                         aria-labelledby="public-resources-tab" tabindex="0">
                        <div class="d-flex flex-wrap">
                            @for (resource of publicResources; track resource) {
                                <div
                                    class="card m-2 text-center"
                                    style="cursor: pointer"
                                    (click)="selectResource(resource)">
                                    <img [src]="resource.Link" [alt]="resource.Description" class="m-auto"
                                         style="width: 150px; height: 150px">
                                    <div class="card-body">
                                        <p class="card-text">{{ resource.Description }}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="tab-pane fade" id="private-resources-tab-pane" role="tabpanel"
                         aria-labelledby="private-resources-tab" tabindex="0">
                        <div class="d-flex flex-wrap">
                            @for (resource of privateResources; track resource) {
                                <div
                                    class="card m-2 text-center"
                                    style="cursor: pointer"
                                    (click)="selectResource(resource)">
                                    <img [src]="resource.Link" [alt]="resource.Description" class="m-auto"
                                         style="width: 150px; height: 150px">
                                    <div class="card-body">
                                        <p class="card-text">{{ resource.Description }}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div class="tab-pane fade" id="private-resources-form-tab-pane" role="tabpanel"
                         aria-labelledby="private-resources-form-tab"
                         tabindex="0">
                        <app-resource-form (resourceChange)="saveResource($event)"
                                           [saving]="savingResource()"></app-resource-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {Injectable} from '@angular/core';
import {UserResponse} from '@services/user.service';
import {User} from '@models/user';
import {Client} from '@models/client';
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {map, Observable, tap} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {UserMapper} from "@mappers/user-mapper";
import {ClientMapper} from "@mappers/client-mapper";
import {Plan} from "@models/plan";

export interface ClientResponse {
    birth: number;
    last_name: string;
    first_name: string;
    id: string;
    name: string;
    username: string;
    gender: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserRepository {
    private fetchingClients: Observable<Client[]>

    constructor(private http: HttpClient, private cookie: CookieService) {
    }

    getClients(): Observable<Client[]> {
        if (this.fetchingClients) {
            return this.fetchingClients;
        }

        this.fetchingClients = this.http.get<ClientResponse[]>(`${environment.api_url}/clients`).pipe(
            map((clients) => {
                return clients.map((client) => new Client(client.id, client.first_name, client.last_name, client.gender, client.birth, client.username, client.name))
            }),
            tap((clients) => {
                this.cacheClients(clients);
                this.fetchingClients = null;
            })
        );

        return this.fetchingClients;
    }

    getCachedCurrentClient(): string | null {
        if (this.cookie.check('current_client')) {
            return this.cookie.get('current_client');
        }

        return null;
    }

    setCachedCurrentClient(client: Client) {
        this.cookie.set('current_client', client.id, undefined, '/');
    }

    /**
     * todo: keep this
     */
    newClient(client: Client): Observable<Client> {
        const body = {
            first_name: client.firstName,
            last_name: client.lastName,
            gender: client.gender,
            birth: client.birth,
            username: client.username,
            password: client.password
        };

        return this.http.post<ClientResponse>('/clients', body).pipe(map((response) => ClientMapper.makeFromResponse(response)));
    }

    getUsersAssociatedToClient(clientId: string): Observable<User> {
        return this.http.get<UserResponse>(`/clients/${clientId}/associate`)
            .pipe(map((response: UserResponse) => UserMapper.makeFromResponse(response)));
    }

    isUsernameValid(username: string): Observable<boolean> {
        return this.http.get<boolean>(`/username/${username}/check`);
    }

    updateSupervisor(user: User): Observable<void> {
        const data = {
            first_name: user.firstName,
            last_name: user.lastName,
            gender: user.gender,
            date_birth: user.birth
        };

        return this.http.put<void>('/account/supervisor-licence', data);
    }

    createClient(client: Client): Observable<Client> {
        const data = {
            first_name: client.firstName,
            last_name: client.lastName,
            gender: client.gender,
            year_birth: client.birth,
            username: client.username,
            password: client.password
        };

        return this.http.post<ClientResponse>('/account/client', data).pipe(map((response) => ClientMapper.makeFromResponse(response)));
    }

    updateClient(client: Client): Observable<true> {
        const data = {
            first_name: client.firstName,
            last_name: client.lastName,
            gender: client.gender,
            year_birth: Number(client.birth),
        };

        return this.http.patch<true>(`/clients/${client.id}`, data).pipe(tap(() => true));
    }

    getCachedUsername(userId: string): string | null {
        return localStorage.getItem(`username-${userId}`);
    }

    setCachedUsername(userId: string, name: string): void {
        return localStorage.setItem(`username-${userId}`, name);
    }

    updatePassword(clientId: string, password: string): Observable<boolean> {
        return this.http.patch<boolean>(`/clients/${clientId}/password`, {password});
    }

    sendVerificationEmail(): Observable<void> {
        return this.http.post<void>('/users/send-mail-confirmation', {});
    }

    getAllCachedClients(): Client[] {
        const cachedClients = localStorage.getItem('clients');
        if (!cachedClients) {
            return [];
        }

        const clients = JSON.parse(cachedClients) as ClientResponse[];
        return clients.map((client) => ClientMapper.makeFromResponse(client));
    }

    private cacheClients(clients: Client[]): void {
        const dataToStore: ClientResponse[] = clients.map((client) => ({
            id: client.id,
            first_name: client.firstName,
            last_name: client.lastName,
            birth: client.birth,
            gender: client.gender,
            username: client.username,
            name: client.name
        }));

        localStorage.setItem('clients', JSON.stringify(dataToStore));
    }

    getUser(id: string): Observable<User> {
        return this.http.get<UserResponse>(`/users/${id}`).pipe(map((response) => UserMapper.makeFromResponse(response)));
    }

    cacheUser(user: User): void {
        const dataToStore = {
            id: user.id,
            first_name: user.firstName,
            last_name: user.lastName,
            gender: user.gender,
            birth: user.birth
        };

        localStorage.setItem(`user-${user.id}`, JSON.stringify(dataToStore));
    }

    getCachedUser(userId: string): User | null {
        if (!localStorage.getItem(`user-${userId}`)) {
            return null;
        }

        const userData = JSON.parse(localStorage.getItem(`user-${userId}`));

        return new User(
            userData.id,
            userData.first_name,
            userData.last_name,
            userData.gender,
            userData.birth
        );
    }

    updateProfile(user: User): Observable<void> {
        const body = {
            first_name: user.firstName,
            last_name: user.lastName,
            gender: user.gender,
            birth: user.birth,
        };

        return this.http.put<void>(`/users/me`, body);
    }

    updateClientProfile(client: Client): Observable<void> {
        const body = {
            first_name: client.firstName,
            last_name: client.lastName,
            year_birth: client.birth,
            gender: client.gender,
        };
        const filtered = Object.fromEntries(Object.entries(body).filter(([_, v]) => v !== null));

        return this.http.patch<void>(`/clients/${client.id}`, filtered).pipe(tap(() => {
            const clients = this.getAllCachedClients();
            const index = clients.findIndex((c) => c.id === client.id);
            clients[index] = client;
            this.cacheClients(clients);
        }));
    }

    switchPlan(plan: Plan): Observable<void> {
        return this.http.post<void>(`/users/me/choose-plan`, {plan_id: plan.id});
        // return this.http.post<void>('http://127.0.0.1:3658/m1/367306-0-default/users/me/choose-plan', {plan_id: plan.id});
    }
}

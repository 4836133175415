import {Injectable} from '@angular/core';
import {ResourceResponse} from "@services/resource.service";
import {Resource} from "@models/resource";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ResourceRepository {
    constructor(private http: HttpClient) {
    }

    publicImages(): Observable<ResourceResponse[]> {
        return this.http.get<ResourceResponse[]>('/resources/public/images');
    }

    privateImages(clientId: string): Observable<ResourceResponse[]> {
        return this.http.get<ResourceResponse[]>(`/resources/private/${clientId}`);
    }

    getPrivateImage(resourceId: number): Observable<Blob> {
        return this.http.get(`/resources/${resourceId}/show`, {responseType : 'blob'});
    }

    uploadImage(clientId: string, resource: Resource): Observable<ResourceResponse> {
        const form = new FormData();
        form.append('description', resource.Description);
        form.append('image', resource.Blob);
        form.append('client_id', clientId);

        return this.http.post<ResourceResponse>(`/resources`, form);
    }

    resourceInfos(resourceId: number): Observable<ResourceResponse> {
        return this.http.get<ResourceResponse>(`/resources/${resourceId}`);
    }

    delete(resourceId: number): Observable<void> {
        return this.http.delete<void>(`/resources/${resourceId}`);
    }
}
